<template>
  <div class="secondary  my-auto pt-16">
    <v-container class="containDiv">
      <v-row class="d-flex justify-center my-auto">
        <v-col cols="10" sm="6" md="6">
          <div class="my-auto px-2 px-md-5 px-lg-10">
            <h1
              class="primary--text font-weight pb-5 text-md-h3 text-xs-h2 text-center"
            >
              Add a customer
            </h1>
            <div class="">
              <p class="my-auto text-center">
                Add a customer to our web-service.
              </p>
            </div>
          </div>

          <v-form class="mx-auto" @submit.prevent="createUser">
            <v-container class=" ">
              <v-row class="d-flex justify-center mx-0">
                <v-col
                  cols="12"
                  md="8"
                  lg="7"
                  xl="6"
                  class="d-flex justify-center"
                >
                  <v-card
                    class="py-lg-8 py-md-4 secondary"
                    elevation="0"
                    width="100%"
                  >
                    <v-row class="d-flex flex-column justify-center">
                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.firstName"
                        label="First name *"
                        :rules="validateRule"
                        color="primary"
                        class="my-2"
                        required
                      ></v-text-field>
                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.lastName"
                        label="Last name *"
                        :rules="validateRule"
                        color="primary"
                        class="my-2"
                        required
                      ></v-text-field>
                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.email"
                        label="E-mail *"
                        type="email"
                        :rules="validateRule"
                        color="primary"
                        class="my-2"
                        required
                      ></v-text-field>
                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.password"
                        label="Password *"
                        :rules="validateRule"
                        hint="Only letters and number are granted"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showpassword ? 'text' : 'password'"
                        @click:append="showpassword = !showpassword"
                        color="primary"
                        class="my-2"
                        required
                      ></v-text-field>

                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.phoneNumber"
                        label="Phone number"
                        hint="Only number are granted"
                        color="primary"
                        class="my-2"
                      ></v-text-field>
                      <v-text-field
                        @change="watchValue()"
                        background-color="secondary"
                        v-model="params.anonymToken"
                        label="Anonym token"
                        color="primary"
                        class="my-2"
                      ></v-text-field
                      ><v-row class="d-flex justify-space-between px-2 mt-auto">
                        <div class="my-auto ml-md-0 mr-auto ml-auto">
                          <a
                            class="text-decoration-underline info--text text-center mx-auto my-auto px-1"
                            @click="reset()"
                          >
                            Reset Form
                          </a>
                        </div>
                        <div
                          class="my-auto mr-md-0 ml-auto mr-auto py-4 py-md-0"
                        >
                          <v-btn
                            width="150"
                            elevation="0"
                            x-large
                            text
                            class="primary"
                            @click="createUser"
                          >
                            Create
                            <v-icon right> mdi-account-plus </v-icon>
                          </v-btn>
                        </div>
                        <v-alert
                          transition="scale-transition"
                          class="text-center success secondary--text mx-auto mt-5 d-flex d-sm-none"
                          v-model="created"
                          v-if="created"
                        >
                          <v-row align="center" class="d-flex flex-column">
                            <v-col class="grow"> Customer created ! </v-col>
                            <v-col class="shrink">
                              <router-link
                                :to="'/customerProfile/' + complexId"
                                class="mx-auto"
                              >
                                <v-btn
                                  class="text-center secondary success--text"
                                >
                                  Go to profile
                                  <v-icon>mdi-account</v-icon>
                                </v-btn>
                              </router-link>
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-row>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col cols="3" sm="5" class="d-sm-flex d-none">
          <v-card
            class="tertiary divRight my-auto mx-auto justify-center align-center d-flex flex-column rounded-xl"
          >
            <v-card
              class="justify-center align-center tertiary px-5 d-sm-flex d-none flex-grow-1 flex-shrink-0"
              elevation="0"
            >
              <v-progress-circular
                :size="250"
                :width="15"
                :value="value_completed"
                color="secondary"
              >
                Filled :
                {{ value_completed }}%
              </v-progress-circular>
            </v-card>
            <v-col
              col="1"
              class="d-flex flex-column mx-auto justify-space-between my-auto"
            >
              <v-card
                class="d-flex justify-center flex-column tertiary"
                elevation="0"
              >
                <p class="seventh--text text-h5 text-center pt-1">
                  Server response :
                </p>
                <v-alert
                  transition="scale-transition"
                  class="text-center success secondary--text"
                  v-model="created"
                >
                  <v-row align="center" class="d-flex flex-column">
                    <v-col class="grow"> Customer created ! </v-col>
                    <v-col class="shrink">
                      <router-link
                        :to="'/customerProfile/' + complexId"
                        class="mx-auto"
                      >
                        <v-btn class="text-center secondary success--text">
                          Go to profile
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-alert>
                <v-alert
                  transition="scale-transition"
                  type="error"
                  class="text-center mx-auto"
                  v-model="error"
                  dismissible
                >
                  {{ message }}
                </v-alert>
              </v-card>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { createUser } from "@/assets/axios/AddCustomer";
import { mapActions } from "vuex";
export default {
  name: "AddCustomer",
  data() {
    return {
      created: false,
      error: false,
      value_completed: 0,
      valid: false,
      message: "",
      showpassword:false,
      complexId: "",
      params: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNumber: "",
        anonymToken: "",
      },
      validateRule: [
        (v) => !!v || "This field is required",
        (v) => v.length > 1,
        (v) => /^[a-zA-Z_ ]*$/.test(v),
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
      
      ],
    };
  },
  methods: {
    ...mapActions("snackbar", ["show"]),
    createUser,
    watchValue() {
      let result = 0;
      if (this.params.firstName !== "") {
        result += 25;
      }
      if (this.params.lastName !== "") {
        result += 25;
      }
      if (this.params.password !== "") {
        result += 25;
      }
      if (this.params.email !== "") {
        result += 25;
      }
      this.value_completed = result;
    },
    triggerMessage(val, msg) {
      if (val === false) {
        this.created = true;
        this.message = msg;
      } else {
        this.error = true;
        this.message = msg;
      }
    },
    reset() {
      this.params.lastName = "";
      this.params.firstName = "";
      this.params.password = "";
      this.params.email = "";
      this.params.phoneNumber = "";
      this.params.anonymToken = "";
      this.value_completed = 0;
      this.show("success", "success");
    },
  },
};
</script>

<style scoped>
input {
  border: 1px solid #c5c9ca;
  height: 30px;
  line-height: 30px;
  width: 280px;
  background-color: white;
  vertical-align: text-bottom;
}
.divRight {
  min-height: 100%;
  z-index: 2;
}
.containDiv {
  padding-bottom: 64px;
}
</style>