import axios from "axios";
import {cleanObject} from '@/assets/js/Methods';
import {REGEX,checkRegex} from '@/assets/js/utils';
export function createUser() {
      let dataTosend=cleanObject({
        email: this.params.email,
        firstName: this.params.firstName,
        lastName: this.params.lastName,
        password: this.params.password,
        phoneNumber: this.params.phoneNumber,
        anonymToken: this.params.anonymToken
      });
      let bolPhoneNumber = ()=>{
      if(this.params.phoneNumber!==null || this.params.phoneNumber!==undefined){
        return REGEX.numberOnly.test(dataTosend.phoneNumber);
      }
      return true;
    }
    if (this.value_completed === 100 && checkRegex(REGEX.letterOnly,dataTosend.firstName,dataTosend.lastName) && bolPhoneNumber() && REGEX.letterAndNumber.test(dataTosend.password)) {
      axios({
        url: this.$store.state.url + "/customer/register/customer/admin",
        params: {
          jsonCustomer: JSON.stringify({...dataTosend})
        },
        method: "POST",
      })
        .then((response) => {
          this.complexId=response.data.complexId;
          this.triggerMessage(response.data.error, response.data.message);
          if(response.data.message!=="E-mail already used"){
            this.reset();
          }          
        })
        .catch((error) => {
          console.log(error);
          this.triggerMessage(error, "error");
        });
    } else {
      this.triggerMessage(true, "One required field is empty or has a wrong value");
    }
}